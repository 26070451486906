/* Just placing it here
*/
/* Adapted from https://codepen.io/Hyperplexed/pen/zYWdYoo */

.card-body {
	/* background-color: rgb(0, 0, 0);   */
	margin: 0px;
	height: 350px;
	width: 520px;

	display: grid;
	place-items: center;
  }

  .card-groups,
  .card-group,
  .card {
	aspect-ratio: 5 / 7;
  }

  .card-groups,
  .card-group,
  .big-card {
	width: 30vmin;
  }

  .card-group {
	position: absolute;
	transition: transform 400ms ease;
  }

  .card-group[data-status="unknown"] {
	transform: scale(0);
	transition: none;
  }

  .card-group[data-status="active"] {
	transition-delay: 300ms;
  }

  .card-group[data-status="after"] {
	transform: translateX(50%) scale(0);
  }

  .card-group[data-status="before"] {
	transform: translateX(-50%) scale(0);
  }

  .card-group[data-status="becoming-active-from-after"] {
	transform: translateX(50%) scale(0);
	transition: none;
  }

  .card-group[data-status="becoming-active-from-before"] {
	transform: translateX(-50%) scale(0);
	transition: none;
  }

  .card {
	background-color: rgba(255, 255, 255, 0.05);
	position: absolute;
	transition: transform 800ms cubic-bezier(.05,.43,.25,.95);

	background-position: center;
	background-size: cover;
  }

  .big-card {
	border-radius: 1vmin;
  }

  .little-card {
	width: 12vmin;
	border-radius: 2vmin;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	/* box-shadow: -1vmin 1vmin 2vmin rgba(0, 0, 0, 0.25); */
	pointer-events: none;
  }

  .big-card:nth-child(2) {
	/* background-image: url("https://images.unsplash.com/photo-1568572933382-74d440642117?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8ZG9nc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60n"); */
  /* background-color: rgb(229, 209, 250); */
  background-image: linear-gradient(to right, rgb(204.29999,242.1,241.5), rgb(209.625,231.9,244.87));
	transform: translateX(-10%) rotate(-1deg);
  }

  .big-card:nth-child(4) {
	/* background-image: url("https://images.unsplash.com/photo-1534351450181-ea9f78427fe8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGRvZ3N8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"); */
  /* background-color: rgb(227, 223, 253); */
  background-image: linear-gradient(to right, rgb(209.625,231.9,244.87), rgb(214.95,221.7,248.25));
	transform: rotate(2deg);
  }

  .big-card:nth-child(6) {
  background-image: linear-gradient(to right, rgb(214.95,221.7,248.25), rgb(220.275,211.5,251.625));
	transform: translateX(-6%) rotate(-3deg);
  }

  .big-card:nth-child(8) {
  background-image: linear-gradient(to right, rgb(220.275,211.5,251.625), rgb(225.6,201.29999,255.0));
	transform: translate(10%, 3%) rotate(5deg);
  }

  .little-card:nth-child(1) {
	background-image: url("../../assets/images/icons/noun-biotechnology.svg");
  }

  .little-card:nth-child(3) {
	background-image: url("../../assets/images/icons/noun-biotechnology-2.svg");
  }

  .little-card:nth-child(5) {
	background-image: url("../../assets/images/icons/noun-public-health.svg");
  }

  .little-card:nth-child(7) {
	background-image: url("../../assets/images/icons/noun-entrepreneurship.svg");
  }

  .card-group:hover > .card {
	/* box-shadow: -2vmin 2vmin 3vmin rgba(0, 0, 0, 0.4); */
  }

  .card-group:hover > .big-card:nth-child(2) {
	transform: translate(-150%, 16%) rotate(-24deg);
  }

  .card-group:hover > .big-card:nth-child(4) {
	transform: translate(-50%, 8%) rotate(-8deg);
  }

  .card-group:hover > .big-card:nth-child(6) {
	transform: translate(50%, 8%) rotate(8deg);
  }

  .card-group:hover > .big-card:nth-child(8) {
	transform: translate(150%, 16%) rotate(24deg);
  }

  .card-group:hover > .little-card:nth-child(1) {
	transform: translate(200%, -160%) rotate(-15deg);
  }

  .card-group:hover > .little-card:nth-child(3) {
	transform: translate(160%, 170%) rotate(15deg);
  }

  .card-group:hover > .little-card:nth-child(5) {
	transform: translate(-200%, -170%) rotate(15deg);
  }

  .card-group:hover > .little-card:nth-child(7) {
	transform: translate(-280%, 140%) rotate(-15deg);
  }

  .card-swiper-buttons {
	margin-top: 8vmin;
	display: flex;
	justify-content: space-around;
	padding: 0vmin 4vmin;
  }

  .card-swiper-buttons > button {
	font-size: 2.5vmin;
	border: 0.4vmin solid rgb(200, 200, 200);
	border-radius: 100%;
	color: white;
	background-color: transparent;
	height: 7vmin;
	width: 7vmin;
	display: grid;
	place-items: center;
	cursor: pointer;
  }

  .text-center {
    text-align: center;
  }

  .card-text {
    /* text-align: center; */
    /* vertical-align: middle; */
    font-family: sans-serif;
    font-weight: bold;
    display: flex;
    align-items: center;
    font-size: x-large;
  }

  #love-button {
	color: rgb(33, 150, 243);
	border-color: rgb(33, 150, 243);
  }

  /* clickable div */
  a.fill-div {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
  }