.hover {
    position: fixed;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    opacity: 0;
    transition: opacity 2s ease-out;
    border-radius: 50%;
}

.hover:before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background-color: #00ff00; /* Change this to your secondary color */
    border-radius: 50%;
    opacity: 0.2;
    pointer-events: none;
    transform: translate(-50%, -50%);
}
